import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { persistStore, persistReducer } from "redux-persist";
import storageSession from "redux-persist/lib/storage/session"; // Read about storage engines here: https://www.npmjs.com/package/redux-persist#storage-engines
import { createStore, applyMiddleware } from "redux";
import { Provider } from "react-redux";
import rootReducer from "./Redux/Reducer/index";
import { PersistGate } from "redux-persist/integration/react";
import { composeWithDevTools } from "redux-devtools-extension";

import { createLogger } from "redux-logger";
import thunk from "redux-thunk";
import { BrowserRouter as Router } from "react-router-dom";

const persistConfig = {
  key: "root",
  storage: storageSession,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const middleware = [thunk];
let store;

if (process.env.NODE_ENV === "development") {
  // If not on localhost, don't include the logger middleware
  store = createStore(persistedReducer, applyMiddleware(...middleware));
} else {
  // If on localhost include the logger middleware

  const loggerMiddleware = createLogger();
  middleware.push(loggerMiddleware);

  store = createStore(
    persistedReducer,
    composeWithDevTools(applyMiddleware(...middleware))
  );
}

let persistor = persistStore(store);
const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <>
    <Router>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <App />
        </PersistGate>
      </Provider>
    </Router>
  </>
);

reportWebVitals();
