export const FORGOTPASSWORD = "/auth/forgot-password";
export const ALBUMS = "/dawahcast/a/";
export const LECTURE = "/dawahcast/l/";
export const RESOURCE_PERSON = "/dawahcast/rp/";
export const VIDEOS = "/dawahcast/videos/";
export const PLAYLISTS = "/dawahcast/pl/";
export const MYPLAYLIIST = "/dawahcast/myplaylist";
export const FAVOURITE = "/dawahcast/favourite";
export const HOME = "/dawahcast/home";
export const MORE = "/dawahcast/more";
export const SEARCH = "/dawahcast/search";
export const LIBRARY = "/dawahcast/library";
export const GENRES = "/dawahcast/genres";
export const CHARTS = "/dawahcast/charts";
export const TRENDING = "/dawahcast/trending";
export const QURAN = "/dawahcast/recitations";
export const NEW = "/dawahcast/new";
export const PLAY = "/dawahcast/playlists";
export const VIDEO = "/dawahcast/videos";
export const LECTURERS = "/dawahcast/lecturers";
export const RECO1 = "/dawahcast/recommend1";
export const RECO2 = "/dawahcast/recommend2";
export const DOWNLOAD = "/dawahcast/download";
export const RAMADAN = "/dawahcast/ramadan";
